.about__section-image {
    border-radius: 5%;
    overflow: hidden;
    width: 30rem;
    
}

.about__section-content h1 {
    margin-bottom: 2rem;
}

.about__section-content p {
    margin-bottom: 1rem;
    font-size: 1.3rem;
}

.about__story-container, .about__Mission-container {
    display: grid;
    grid-template-columns: 40% 50%;
    gap: 10%;
}

.about__Vision-container {
    grid-template-columns: 50% 40%;
    gap: 10%;
    display: grid;
}

.about__headcontainer h2 {
    width: 100%;
    text-align: center;
    margin-top: 8rem;
    font-size: 2.7rem;
    font-weight: 400;
}

.about__headcontainer h2:hover {
    width: 100%;
    text-align: center;
    margin-top: 8rem;
    font-size: 2.7rem;
    font-weight: 400;
    text-decoration: underline;
    transition: var(--transition);
}

.portfolio__container {
    margin-top: 8rem;   
}

/*Media Queries (Medium Screens)*/

@media screen and (max-width:1024px) {
    .about__section-image {
        width: 60%;
    }
    .about__section-content {
        margin-bottom: 1.2rem;
    }
    .about__story-container, 
    .about__Vision-container,
    .about__Mission-container {
        grid-template-columns: 1fr;
        gap: 3rem
    }

    .about__Vision .about__section-image {
        grid-row: 1;
    }
}

/*Media Queries (Small Screens)*/

@media screen and (max-width:600px) {

    .about__section-image {
        width: 80%;
        margin-inline: auto;
    }    
    .about__story-container, 
    .about__Vision-container,
    .about__Mission-container {
        gap: 2rem; 
    }

}