.main__header{
    width: 100vw;
    height: calc(100vh - 3rem);
    display: grid;
    place-items: center;
    margin-top:3rem;   
}

.main__header-container {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: space-between;
    gap: 6rem;

}

.main__header-left {
    margin-top: -3rem
}

.main__header-left h4 {
    margin-bottom: 1rem;
    color: var(--color-secondary);
    font-weight: 400;
    font-size: 1.4rem;
}

.main__header-left p{
    margin: 1rem 0 3.5rem;
    font-size:1.1rem;

}

.main__header-right{
    display: grid;
    place-items: center;
    position: relative;
}

.main__header-circle{
    width: 40rem;
    height: 40rem;
    background: linear-gradient(75deg, red, transparent);
    border-radius: 50%;
    position: absolute;
    filter: blur(15px);
}


.main__header-image {
    position: relative;
}

/* ================================= Programs ================================ */

.programs {
    margin-top: 4rem;
}

.programs__warpper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 3rem;
    margin-top: 4rem;
}

.programs__program a {
    margin-top: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.programs__program:hover a{
    background: var(--color-secondary);
    color: var(--color-gray-600);
}


/* ================================= Values ================================ */

.values__container {
    display: grid;
    grid-template-columns: 38% 50%;
    gap: 12%;
}

.values__image {
    filter: saturate(0.25);
    transition: var(--transition);
    margin-top: 2rem;
}

.values__image:hover {
    filter: saturate(1);
}

.values__right p {
    margin: 1.5rem 0 5rem;
    font-weight: 500;
    font-size: larger;
}

.values__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3.4rem 2.4rem;
}

.card.values__value {
    padding-top: 3.5rem;
    text-align:left;
    position: relative;
}

.card.values__value span {
    position: absolute;
    top: -1.5rem;
}

.brand__container {
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
    margin-top: 3rem;
    background-color: whitesmoke;
    height: 80px;
    padding-top: 1.3rem;
    border-radius: 25px;
}


/* ================================= FAQS ================================ */

.faqs__wrapper {
    margin-top: 4rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem 5rem;
    align-items: flex-start;
}

.faq {
    background: whitesmoke;
    padding: 2rem;
    border-radius: 1rem;
    cursor: default;
}

.faq div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faq__icon {
    background: transparent;
    color: var(--color-gray-100);
    font-size: 1.5rem;
}

.faq p {
    margin-top: 1.5rem;
}


/* ================================= Testimonials ================================ */

.testimonials { 
    align-items: center;
    text-align: center;  
}

.testimonials__head {
    justify-content: center;
}



.card.testimonials {
    position: relative;
    text-align: left;
    margin-top: 5rem;
    
    align-items: center;
    text-align: center; 
}

.testimonials__avatar {
    width: 5rem;
    height: 5rem;
    border-radius: 1.5rem;
    border: 2px solid transparent;
    overflow: hidden;
    position: absolute;
    top: -2rem;
    left: calc(50% - 2rem);
    box-shadow: 0 1.5rem 2rem rgb (26, 26, 54, 0.4);
    transition: var(--transition);
}

.testimonials:hover .testimonials__avatar {
    border-radius: 50%;
    border-color: var(--color-gray-4x   00);
}

.testimonial__quote {
    font-style: italic;
    margin: 1rem 0 2rem;
}

.card small.testimonial__title {
    margin-top: 0.6rem;
    margin-bottom: 0.3rem;
}

.testimonials__btn-container {
    width: fit-content;
    margin:  2.5rem auto 0;
    display: flex;
    justify-content: center;
    gap: 1rem;
}
.testimonial__btn {
    background: transparent;
    color: var(--color-secondary);
    font-size: 1.8rem;
    width: 3rem;
    height: 3rem;
}

.testimonial__btn:hover {
    background: var(--color-gray-600);
    color: var(--color-primary-variant);
    font-size: 2rem;
    width: 3rem;
    height: 3rem;
}


/* ================================= Footer ================================ */

footer {
    background: white;
    margin-top: 7rem;
    padding-top: 7rem;
    font-size: 0.9rem;
    color: var(--color-gray-100);
}

.footer__container {
    display: grid;
    grid-template-columns: 26rem 1fr 1fr 1fr;
    gap: 6rem;
}

.footer__container article {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.7rem;
}

.footer__container article p {
    margin-top: 0.5rem;
}

.footer__container article h4 {
    margin-bottom: 0.6rem;
    font-size: 1rem;
}

.footer__socials {
    margin-top: 1.5rem;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.footer__socials a {
    background: var(--color-gray-100);
    padding: 0.7rem;
    border: 1px solid transparent;
    border-radius: 0.6rem;
    transition: var(--transition);
}

.footer__socials a svg {
    color: var(--color-gray-600);
}

.footer__socials a:hover {
    border-color: var(--color-gray-100);
    background: transparent;
}

.footer__copyright {
    color: var(--color-gray-100);
    text-align: center;
    padding: 1.5rem 0;
    border-top: 2px solid var(--color-primary-variant);
    margin-top: 5rem;
}


/*Media Queries (Medium Screen)*/

@media screen and (max-width: 1024px) {
    /*MAIN HEADER*/
    .main__header {
        height: fit-content;
        padding: 12rem 0;
    }
    .main__header-container {
        gap: 0;

    }
    .main__header-circle {
        width: 16rem;
        height: 16rem;
    }

    /*-------Programs-------*/

    .programs__warpper {
        grid-template-columns: 1fr 1fr;
    }

    /*--------- VALUES ---------*/

    .values__container {
        grid-template-columns: 1fr;
        justify-content: center;
        gap: 4rem;
    }

    .values__wrapper {
        gap: 4rem 3rem;
    }

    .values__image {
        display: none;
    }

    /*--------- FAQ ---------*/ 
    
    .faqs__wrapper {
        grid-template-columns: 1fr;
    }

    /*---------Testimonials----------*/

    .testimonials {
        width: 75%;
    }

    /*-------Footer---------*/

    .footer__container{
        grid-template-columns: 1fr 1fr;
        gap: 4rem;
    }
    
}



/*Media Queries (Small Screen)*/

@media screen and (max-width: 600px) {
    /*-----Main Header--------*/
    .main__header {
        margin-top: 0;
        padding: 12rem 0 0;
        height: 100vh;
    }

    .main__header-image, .main__header-circle {
        display: none;
    }

    .main__header-container {
        grid-template-columns: 1fr;
        text-align: center;
    }

    /*------ Programs------*/
     .programs__warpper {
        grid-template-columns: 1fr;
        gap: 2rem;
        margin-top: 3rem;
    }

    .programs__program {
        width: 84%;
        margin: 0 auto;
    }
   /*------ Values ------*/  
   .values__wrapper {
    grid-template-columns: 1fr;
    gap: 3rem;
    }
    
    .card.values__value{
        width: 84%;
        margin-inline: auto;
    }

    /*--------- FAQ ---------*/ 
    
    .faqs__wrapper {
    gap: 1rem;
    margin-top: 3rem;
    }
    .footer__container{
        text-align: center;
    }

    /*---------Testimonials----------*/

    .testimonials {
    width: 100%;
    }

    /*-------Footer---------*/

    .footer {
        margin-top: 7rem;
    }
    .footer__container{
    grid-template-columns: 1fr;
    gap: 3rem;
    }
    .footer__container article{
    align-items: center;
    }
    .footer__container p{
    text-align: center;
    }
}
