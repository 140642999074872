.trainers__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4rem;
}

.card.trainer {
    padding: 1rem;
}

.card.trainer:hover {
    
    color: var(--color-secondary);
}

.trainer__img {
    border-radius: 1rem;
    overflow: hidden;
    height: 499px;
}

.trainer p {
    margin-top: 0.5rem;
    margin-bottom: 0.8rem;
}

.trainer p:hover {
    
    color: var(--color-secondary);
}

.trainer h3 {
    margin-top: 0.5rem;
}


.trainer__socials {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
}

.trainer a {
    font-size: 0.7rem;
    background-color: var(--color-gray-600);
    padding: 0.8rem;
    border-radius: 0.7rem;
    transition: var(--color-transition);
}

.trainer a:hover {
    background-color: var(--color-secondary);
}

.team__headcontainer h2 {
    width: 100%;
    text-align: center;
    margin-top: 8rem;
    font-size: 2.7rem;
    font-weight: 400;
}

#ksiHide {
    display: none;
}


.team__headcontainer h2:hover {
    width: 100%;
    text-align: center;
    margin-top: 8rem;
    font-size: 2.7rem;
    font-weight: 400;
    text-decoration: underline;
    transition: var(--transition);
}


/* Media Queries (Medium screens)*/

@media screen and (max-width:1024px) {
    .trainers__container {
        grid-template-columns: 1fr  1fr;
        gap: 2rem;
        display: grid;
    }
}


/*Media Queries (Small Screen)*/

@media screen and (max-width:600px) {
    .trainers__container {
        grid-template-columns:  1fr;
        gap: 1rem;
    }

    .card.trainer {
        width: 90%;
        margin-inline: auto ;
    }
}