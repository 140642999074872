.section-whit {
    background-color: azure;
    padding: 70px 0;
}

.team-item {
    background-color: #f2f2f2 ;
    text-align: center;
    margin: 20px 0;
    padding: 50px 20px 40px 20px;
    border-radius: 8px 8px;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition:all 0.25s ease-in-out ;
    -ms-transition:all 0.25s ease-in-out ;
    -o-transition: all 0.25s ease-in-out;
    transition:all 0.25s ease-in-out ;
    border: solid var(--color-secondary);
}


.team-item:hover {
    border-radius: 50px;
    background-color: transparent;
    transition: var(--transition);
    
}

.team-item h3 {
    margin: 20px 1px 0 !important;
    color: #454545;
    font-size: 18px;
    font-weight: 800;
    text-transform: uppercase;
    transition:all 0.25s ease-in-out  ;
}

.team-info {
    display: block;
    margin: 0px;

}

.team-item img {
    border-radius: 10px;
    align-items: center;
    text-align: center;
}

.founder {
    font-weight: 800;
    font-size: large;
}

.team-info span {
    font-weight: 900;
    font-size: large;
    font-style: normal;
}

.team-info p {
    color: #454545;
    margin-top: 1rem;
    font-style: italic;
}

.team-icon a {
    color: red;
    display: inline;
    margin: 0px;
    display: inline-block;
    padding: 1px 1px;
    width: 50px;
    height: 50px;
    font-size: 20px;
    line-height: 81px;
}

.ul.team-icon {
    margin-top: 25px;
}

.ul.team-icon li {
    display: inline-block;
    margin: 0 3px;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition:all 0.25s ease-in-out ;
    -ms-transition:all 0.25s ease-in-out ;
    -o-transition: all 0.25s ease-in-out;
    transition:all 0.25s ease-in-out ;
}

.ul.team-icon li a {

    margin: 0px;
    display: inline-block;
    padding: 8px 8px;
    width: 34px;
    height: 34px;
    font-size: 18px;
    line-height: 18px;
    background-color: ;
}
