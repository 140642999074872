.contact__container {
    display: grid;
    place-items: center;
}

.contact__wrapper {
    width: fit-content;
    display: flex;
    gap: 2rem;
}

.contact__wrapper a {
    background-color: var(--color-primary);
    font-size: 2rem;
    border: 2px solid transparent;
    transition: var(--transition);
    padding: 2rem;
    border-radius: 1.5rem;
    margin-top: 7rem;
    color: white;
    text-decoration: none;
    text-align: center;
}

.contact__wrapper a:hover {
    background-color: var(--color-secondary);
    border-color: var(--color-gray-100);
    color: black;
}

.contact__headcontainer h2 {
    width: 100%;
    text-align: center;
    margin-top: 8rem;
    font-size: 2.7rem;
    font-weight: 400;
}



.contact__headcontainer h2:hover {
    width: 100%;
    text-align: center;
    margin-top: 8rem;
    font-size: 2.7rem;
    font-weight: 400;
    text-decoration: underline;
    transition: var(--transition);
}

/* MEdia Queres (small Screen)*/

@media screen and (max-width:600px) {
    .contact__wrapper {
        gap: 1.5rem;
    }

    .contact__wrapper a {
        padding: 1rem;
        font-size: 1.2rem;
        border-radius: 1rem;
    }
}