.gallery__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.gallery__container article {
    border: 1rem solid transparent;
    transition: var(--transition);
}

.gallery__container article:hover {
    border-color: var(--color-gray-500);
}

.gallery__headcontainer {
    width: 100%;
    text-align: center;
    margin-top: 5rem;
    
}

.gallery__headcontainer p{
    margin-top: 3rem;
}


.gallery__headcontainer h2 {
    width: 100%;
    text-align: center;
    margin-top: 8rem;
    font-size: 2.7rem;
    font-weight: 400;
    
}



/*Media Queries (Medium Screen)*/

@media screen and (max-width: 1024px) {
    .gallery__container {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }

    .gallery__headcontainer h2 {
        margin-top: 1rem;
    }

    .gallery__container article {
        border: 0;
    }
    
}

/*Media Queries (Small Screen)*/

@media screen and (max-width: 600px) {
    .gallery__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .gallery__headcontainer h2 {
        margin-top: -5rem;
    }
}