nav {
    height: 9rem;
    width: 100%;
    background: #ffffff;
    display: grid;
    place-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    font-weight: 500;

    
}


.nav {
    height: 9rem;
    width: 100%;
    background: #ffffff;
    display: grid;
    place-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    font-weight: 500;

}

/* only shows on medium */

.nav__toggle-btn{
    display: none;
}

.nav__container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.logo {
    width: 160px;
    display: block;
    
}

.kos {
    background-color: #ffffff;
    width: ;
}


.nav__links {
    display: flex;
    gap: 3.5rem;
    align-items: center;
}

.nav__links a {
    transition: var(--transition);
    font-size: 1.1rem;
    color: black;
    text-decoration: none;
}

.nav__links a:hover {
    transition: var(--transition);
    font-size: 1.2rem;
}


.nav__links a:hover {
   color: var(--color-secondary);
}


.active-nav {
    position: relative;
}
    
.active-nav::after {
    content: '';
    display: block;
    width : 0.3rem;
    height: 0.3rem;
    background: red;
    position: absolute;
    left: calc(50% - 0.6rem);
    transform: rotate(45deg);
    margin-top: 0.3rem;
}

.active-nav:hover::after {
    content: '';
    display: block;
    width : 0.9rem;
    height: 0.9rem;
    background: var(--color-secondary);
    position: absolute;
    left: calc(50% - 0.6rem);
    transform: rotate(45deg);
    margin-top: 0.3rem;
    transition: var(--transition);
}



/*Media Medium And Small Screens  */

@media screen and (max-width: 1024px) {

    .nav__container {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        text-align: center;
        
    }

    .nav__toggle-btn {
        display: inline-block;
        background: transparent;
        font-size: 1.8rem;
        cursor: pointer;
        
    }

    .nav__toggle-btn svg {
        color: var(--color-gray-100);
    }

    .nav__links {
        position: absolute;
        top: 100%;
        right: 0;
        flex-direction: column;
        gap: 0;
        background: white;
        margin-right: 4rem;
    }

    .active-nav, .active-nav::after {
        display: none;
    }

    .nav__link li {
        height: 4rem;
        width: 100%;
        box-shadow: -2rem 2rem 5rem rgba(0, 0, 0, 0.4);  
    }
    .nav__links li a {
        background-color: var(--color-primary);
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 1rem 5rem 1rem 3rem;
        background-color: white;
    }
    .show__nav {
        display: flex;
    }
    .hide__Nav {
        display: none;
    }

}


/*Media Queres (For small Screen)*/

@media screen and (max-width:600px){

    .nav__container {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        text-align: center;
    }

}
