.services__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
}
.services {
    text-align: center;   
}

.blend__container link {
   background-color: yellow;
}

.services h3 {
    margin-bottom: 0.8rem;
}

.services small {
    margin-bottom: 2rem;
}

.services h1 {
    color: var(--color-primary-variant);
    display: inline;
}

.services h4 {
    margin-top: 2rem;
}

.services p {
    margin-top: 1rem;
    color: var(--color-gray-100);
}

.services .btn-lg {
    margin-top: 8rem;
    display: inline-block;
    color: white;
    width: fit-content;
    padding: 0.9rem 2rem;
    background: var(--color-primary);
    border-radius: 1.5rem;
    transition: var(--transition);
    font-size: 1.2rem;
    font-weight: 500;
    
}

.services .btn-lg:hover {
    margin-top: 8rem;
    display: inline-block;
    background-color: var(--color-secondary);
    color: var(--color-gray-100);
    width: fit-content;
    padding: 0.9rem 2rem;
    border-radius: 1.5rem;
    transition: var(--transition);
    
}

.services__headcontainer h2 {
    width: 100%;
    text-align: center;
    margin-top: 8rem;
    font-size: 2.7rem;
    font-weight: 400;
}

.services__headcontainer h2:hover {
    width: 100%;
    text-align: center;
    margin-top: 8rem;
    font-size: 2.7rem;
    font-weight: 400;
    text-decoration: underline;
    transition: var(--transition);
}

.excel__container {
    margin-bottom: 5rem;
}

.blend__container {
    font-size: 1.5rem;
    margin-top: 5rem;
    margin-bottom: 5rem;
}

/*MEdia Queries (Medium Screen)*/

@media screen and (max-width:1024px){
    .services__container{
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
}



/*MEdia Queries (Small Screen)*/

@media screen and (max-width:600px){
    
    .services__container {
        grid-template-columns: 1fr;
        width: 10%;
    }

    .services {
        width: 90%;
        margin-inline: auto;
    }
}